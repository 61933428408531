import { Box, Button, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import Form from './Form';
import Table from './Table';
import { createReport, getReports } from '../../utils/API';
import { useFeedback } from '../../utils/FeedbackContext';
import { useAuth } from '../../utils/AuthContext';

export default function Report({
  title,
  reportType,
  fieldsList = [],
  isFaculty = false,
}) {
  const [fields, setFields] = useState(
    fieldsList.reduce((acc, field) => ({ ...acc, [field]: '' }), {})
  );
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [flag, setFlag] = useState(false);
  const { notifyError, notifySuccess } = useFeedback();
  const { user } = useAuth();
  const [disabledFields, setDisabledFields] = useState([]);

  const handleSubmit = async () => {
    try {
      const response = await createReport(reportType?.toLowerCase(), {
        ...fields,
        year: parseInt(fields?.year),
      });
      notifySuccess(response?.data?.message);
      setFlag(!flag);
    } catch (err) {
      console.log(err);
      notifyError(
        err?.response?.data?.errMessage || err?.response?.data?.message
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getReports(reportType);
        const temp = response?.data?.columns.map((row) => {
          if (row?.field === 'file') {
            return {
              ...row,
              renderCell: (params) => (
                <Button
                  variant="contained"
                  href={params?.row?.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </Button>
              ),
            };
          }
          return row;
        });
        setColumns(temp);
        setRows(response?.data?.rows);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [reportType, flag]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!isFaculty) {
      return;
    }

    if (isFaculty) {
      if (user?.department === 'ME' && fields?.course === '') {
        setFields({ ...fields, course: 'BOTH' });
        setDisabledFields((prev) => [...prev, 'course']);
      }

      if (fields?.role === '') {
        setFields({ ...fields, role: 'STUDENT' });
        setDisabledFields((prev) => [...prev, 'role']);
      }

      if (fields?.department === '') {
        setFields({ ...fields, department: user?.department });
        setDisabledFields((prev) => [...prev, 'department']);
      }
    }
  }, [user, isFaculty, fields]);

  return (
    <Paper sx={{ border: 1.5 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ fontSize: 26, fontWeight: '500', borderBottom: 1.5 }}
        padding={1}
      >
        {title}
      </Box>
      <Form
        fieldsList={fieldsList}
        fields={fields}
        setFields={setFields}
        handleSubmit={handleSubmit}
        disabledFields={disabledFields}
      />
      {rows && columns && <Table rows={rows} columns={columns} />}
    </Paper>
  );
}
