import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../components/Admin/Dashboard';
import { Styles } from '../../../styles/global';
import Report from '../../../components/Reports';
import { useAuth } from '../../../utils/AuthContext';
import FacultyDashboard from '../../../components/Faculty/Dashboard';

function Space({ num = 2 }) {
  return (
    <Grid item lg={num} sm={0} xs={0}>
      <div />
    </Grid>
  );
}

function Reports() {
  const { user } = useAuth();
  const isFaculty = user?.role === 'FACULTY';
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            <Space />

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={8} md={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}> Reports </Paper>
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 2: SCORES */}
            {user?.role === 'ADMIN' && (
              <>
                {' '}
                <Grid item lg={8} md={12} xs={12}>
                  <Report
                    title="Scores"
                    fieldsList={[
                      'course',
                      'department',
                      'year',
                      'status',
                      'type',
                    ]}
                    reportType="SCORES"
                  />
                </Grid>
                <Space />
                <Space />
              </>
            )}

            {/*Inner Grid Item 3: STATIONS */}
            {user?.role === 'ADMIN' && (
              <>
                <Grid item lg={8} md={12} xs={12}>
                  <Report
                    title="Stations"
                    fieldsList={['course', 'department', 'year']}
                    reportType="STATIONS"
                    isFaculty={isFaculty}
                  />
                </Grid>
                <Space />

                <Space />
              </>
            )}

            {/*Inner Grid Item 4: PROJECTS */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title="Projects"
                fieldsList={['course', 'department', 'year']}
                reportType="PROJECTS"
                isFaculty={isFaculty}
              />
            </Grid>

            <Space />

            <Space />

            {/*Inner Grid Item 5: USER */}
            <Grid item lg={8} md={12} xs={12}>
              <Report
                title={user?.role === 'ADMIN' ? 'Users' : 'Students'}
                fieldsList={['course', 'department', 'year', 'role']}
                reportType="USERS"
                isFaculty={isFaculty}
              />
            </Grid>

            <Space />

            <Space />

            {user?.role === 'ADMIN' && (
              <>
                {/*Inner Grid Item 6: JUDGES */}
                <Grid item lg={8} md={12} xs={12}>
                  <Report
                    title="Judges"
                    fieldsList={['year']}
                    reportType="JUDGES"
                    isFaculty={isFaculty}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardReports() {
  const { user } = useAuth();
  if (user?.role !== 'ADMIN') {
    return (
      <FacultyDashboard Page={Reports} PageName="(F) Dashboard - Reports" />
    );
  }
  return <Dashboard Page={Reports} PageName="(A) Dashboard - Reports" />;
}
