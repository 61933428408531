import { useAuth } from '../AuthContext';
import {
  PROJECT_ADMIN_SECTIONS as sections,
  PROJECT_ADMIN_FIELDS as fields,
} from './constants';

const AdminProjectsSchema = ({ avaliableYears }) => {
  const { user } = useAuth();
  const isME = user?.department === 'ME' || false;
  return {
    [sections.course]: {
      title: {
        value: sections.course,
      },
      fields: {
        name: fields.course.name,
        type: 'radio',
        label: 'Course',
        placeholder: 'Select course',
        required: false,
        getOptions: () =>
          !isME
            ? fields.course.options
            : fields.course.options.filter((option) => option.value !== 'SD1'),
      },
    },
    [sections.year]: {
      title: {
        value: sections.year,
      },
      fields: {
        name: fields.year.name,
        type: 'select',
        label: 'Year',
        placeholder: 'Select year',
        required: false,
        getOptions: () => fields.year.options(avaliableYears),
      },
    },
    [sections.limit]: {
      title: {
        value: sections.limit,
      },
      fields: {
        name: fields.limit.name,
        type: 'select',
        label: 'Year',
        placeholder: 'Select limit',
        required: false,
        getOptions: () => fields.limit.options,
      },
    },
  };
};

export default AdminProjectsSchema;
