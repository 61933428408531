import {
  Alert,
  Box,
  Container,
  Fab,
  Grid,
  Paper,
  Toolbar,
  Tooltip,
} from '@mui/material';
import Dashboard from '../../../../components/Admin/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import {
  getAvailableYears,
  getTutorials,
  createTutorial,
} from '../../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import Filters from '../../../../components/Filters';
import projectsFiltersSchema from '../../../../utils/FiltersSchema/AdminProjects';
import TutorialsView from '../../../../components/Views';
import { AddCircleSharp } from '@mui/icons-material';
import AdvancedModal from '../../../../components/AdvancedModal';
import TutorialForm from '../../../../components/Tutorials';
import { useFeedback } from '../../../../utils/FeedbackContext';

function FiltersSidebar({ avaliableYears }) {
  const [tutorial, setTutorial] = useState({
    published: true,
    title: '',
    description: '',
    content: [],
  });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [searchParams] = useSearchParams();
  const { notifyError, notifySuccess } = useFeedback();
  const handleSave = async () => {
    try {
      let params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      await createTutorial({ ...params, ...tutorial });
      notifySuccess('Tutorial created successfully');
      setOpen(false);
    } catch (error) {
      setError(error.message);
      notifyError('Error creating tutorial');
    }
  };

  return (
    <Box {...Styles.dashboardContentWidget('auto')}>
      <Filters
        filtersSchema={projectsFiltersSchema({
          avaliableYears: avaliableYears,
        })}
        searchType="tutorials"
      />
      <br />
      {searchParams.get('course') &&
        searchParams.get('department') &&
        searchParams.get('year') && (
          <Box textAlign="center">
            <Tooltip title="Add Tutorial" aria-label="add tutorial">
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpen(true)}
              >
                <AddCircleSharp />
              </Fab>
            </Tooltip>
            <AdvancedModal
              open={open}
              handleClose={() => setOpen(false)}
              handleSave={handleSave}
              title="Create a Tutorial"
            >
              <TutorialForm tutorial={tutorial} setTutorial={setTutorial} />
              {error && (
                <Alert severity="error" sx={{ marginY: 1 }}>
                  {error}
                </Alert>
              )}
            </AdvancedModal>
          </Box>
        )}
    </Box>
  );
}

function Tutorials() {
  const [tutorials, setTutorials] = useState([]);
  const [years, setYears] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page

  useEffect(() => {
    if (
      !searchParams.get('search') ||
      searchParams.get('search') !== 'tutorials'
    ) {
      setSearchParams('search=tutorials');
    }
    if (!searchParams.get('year')) {
      setSearchParams(
        `${searchParams.toString()}&year=${new Date().getFullYear()}`
      );
      if (!searchParams.get('limit')) {
        setSearchParams(`${searchParams.toString()}&limit=10`);
      }
      if (!searchParams.get('page')) {
        setSearchParams(`${searchParams.toString()}&page=1`);
      }
    }
    searchParams.delete('type');
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchAvailableYears() {
      try {
        const yearsResponse = await getAvailableYears(
          searchParams.get('department' || '')
        );
        const availableYears =
          yearsResponse?.data?.data?.sort(function (a, b) {
            return b - a;
          }) || [];
        setYears(availableYears.map((year) => year.toString()));
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchTutorials() {
      try {
        const removeParams = ['search'];
        removeParams.forEach((param) => searchParams.delete(param));
        const tutorialsResponse = await getTutorials(searchParams.toString());
        setTutorials(tutorialsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      fetchAvailableYears();
      fetchTutorials();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: Space Taken */}
            <Grid item lg={3} sm={0} xs={0} ref={topRef}>
              <div />
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={9} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Tutorials</Paper>
            </Grid>

            {/*Inner Grid Item 2: Filters */}
            <Grid item lg={3} sm={12} xs={12}>
              <FiltersSidebar avaliableYears={years} />
            </Grid>

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={9} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <TutorialsView
                  items={tutorials}
                  topRef={topRef}
                  link="/admin/dashboard/tutorials"
                  itemType={'tutorials'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function FacultyDashboardTutorials() {
  return <Dashboard Page={Tutorials} PageName="(A) Dashboard - Tutorials" />;
}
