import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider } from '@mui/material';
import { SearchProject } from './Entity';

export default function AccordionUsage({
  title,
  completeList,
  incompleteList,
}) {
  return (
    <Box padding={2} margin={3}>
      <h3>{title}</h3>
      <Divider />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Completed: {`(Results: ${completeList?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {completeList && (
            <>
              {completeList.map((project) => (
                <SearchProject key={project?._id} project={project} hideImage />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Pending: {`(Results: ${incompleteList?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {incompleteList && (
            <>
              {incompleteList.map((project) => (
                <SearchProject key={project?._id} project={project} hideImage />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
