import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/AuthContext.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getMainConfig,
  getProject,
  getProjectInvites,
  acceptInvite,
  declineInvite,
  uploadProfileImage,
  uploadProfileResume,
  updateMeStudent,
} from '../../../utils/API.js';
import {
  InformProjectAbstract,
  InformPosterImage,
  InformPosterLink,
  InformStation,
  InformProjectMembers,
  InformProjectDetails,
  InformTimeline,
  InformInbox,
} from '../../../components/Student/Widgets';

import {
  Alert,
  Checkbox,
  TextField,
  Typography,
  Divider,
  Button,
  Avatar,
} from '@mui/material';
import { formatDate } from '../../../utils/dates.js';
import useFeedback from '../../../utils/FeedbackContext/useFeedback.js';

function getColorForRole(role) {
  const colors = {
    ADMIN: '#1F6CFA',
    STUDENT: '#085C25',
    JUDGE: '#673AB7',
    FACULTY: '#1F6CFA',
  };
  return colors[role] || 'red'; // Default color if the role is not found
}

const DetailRow = ({ label, value, useDiv = false }) => {
  if (!value) return <></>;

  return (
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
      <Typography variant="body1" fontFamily={'sans-serif'} fontWeight={600}>
        {label}
      </Typography>
      {!useDiv && <Typography variant="body1">{value}</Typography>}
      {useDiv && <Box sx={{ display: 'flex', gap: 1 }}>{value}</Box>}
    </Box>
  );
};

// Inform Icon & Resume Widget
const InformIconResume = ({ project }) => {
  const { user } = useAuth();
  const isMe = true;
  const isStudent = user?.role === 'STUDENT' || true;

  const resume = user.resume ? (
    <Link
      to={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_RESUMES}${user.resume}`}
      target="_blank"
    >
      View Resume
    </Link>
  ) : (
    'No resume uploaded'
  );

  const Icon = user?.icon ? (
    <Avatar
      alt={user.fullName}
      src={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_ICONS}${user?.icon}`}
      sx={{ width: 80, height: 80 }}
    />
  ) : (
    <Avatar
      sx={{
        bgcolor: getColorForRole(user.role),
        fontSize: '18px',
      }}
    >
      {(user.firstName[0] + user.lastName[0]).toUpperCase()}{' '}
    </Avatar>
  );

  const { notifySuccess, notifyError } = useFeedback();

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const res = await uploadProfileImage(formData);
      notifySuccess('Profile picture uploaded successfully');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      notifyError('Failed to upload profile picture');
    }
  };

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('resume', file);

    try {
      const res = await uploadProfileResume(formData);
      notifySuccess('Resume uploaded successfully');
      // refresh after 2 seconds
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      notifyError('Failed to upload resume');
    }
  };

  return (
    <Box>
      <Typography {...StylesForm.text('h6')}>Profile Icon & Resume</Typography>
      <Divider />
      <Box display="flex" flexDirection="column" gap={1} padding={2}>
        <DetailRow label="System Icon" value={Icon} useDiv={true} />
        {isMe && (
          <Button variant="outlined" color="primary" component="label">
            Upload Profile Picture{' '}
            <input type="file" hidden onChange={handleUpload} />
          </Button>
        )}
        <DetailRow label="Resume" value={resume} />
        {isMe && isStudent && (
          <Button variant="outlined" color="primary" component="label">
            Upload Resume (PDF)
            <input type="file" hidden onChange={handleResumeUpload} />
          </Button>
        )}
        {isMe && isStudent && (
          <Alert variant="outlined" severity="info" fontFamily={'sans-serif'}>
            Adding your profile picture and resume is not required but can be
            helpful for judges and recruiters to learn more about you and can
            lead to professional opportunities.
          </Alert>
        )}
      </Box>
    </Box>
  );
};

// From to capture Project TITLE & DESCRIPTION
const ProjectIdentity = ({ form, setForm }) => {
  return (
    <>
      {/* Project Identity Title */}
      <Typography {...StylesForm.text('h6')}>Project Identity</Typography>
      <Divider />

      {/* Start of Form: Project & Description Names */}
      <>
        <TextField
          {...StylesForm.input('projectName', 'Project Tilte')}
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />

        <TextField
          {...StylesForm.input(
            'projectDescription',
            'Project Description (One Sentence - Two Sentences)',
            true,
            true
          )}
          InputProps={{
            rows: 6,
            multiline: true,
            inputComponent: 'textarea',
            maxLength: 600,
          }}
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
      </>
      {/* End of Form: Project & Description Names */}
    </>
  );
};

// Form to capture CLIENT && INSTRUCTOR NAMES
const ProjectDetails = ({ form, setForm }) => {
  return (
    <>
      {/* Project Details Title */}
      <Typography {...StylesForm.text('h6')}>Project Details</Typography>

      <Divider />

      {/* Start of Form: Client & Instructor Names */}
      <>
        <TextField
          {...StylesForm.input(
            'clientNames',
            'Client(s) Names (First Last, ... , First Last)'
          )}
          value={form.clients}
          onChange={(e) => setForm({ ...form, clients: e.target.value })}
        />

        <TextField
          {...StylesForm.input(
            'instructorNames',
            "Instructor(s) Names (First Last, ... , First Last)'"
          )}
          InputProps={{
            rows: 5,
            multiline: true,
            inputComponent: 'textarea',
            maxLength: 600,
          }}
          value={form.instructor}
          onChange={(e) => setForm({ ...form, instructor: e.target.value })}
        />
      </>
      {/* End of Form: Client & Instructor Names */}
    </>
  );
};

// Student Details Widget (To confirm)
const StudentDetails = ({ form, setForm }) => {
  const { user } = useAuth();
  return (
    <>
      {/* Title of Widget */}
      <Typography {...StylesForm.text('h6')}>Student Details</Typography>

      <Divider />

      {/* Beginning of Student Details*/}
      <Box display={'flex'} flexDirection={'column'}>
        <Typography {...StylesForm.text('p')}>
          Course Equivalent : <strong>{`${user.course}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Department : <strong>{`${user.department}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Competition Participant Year : <strong>{`${user.year}`}</strong>
        </Typography>
      </Box>
      {/* End of Student Details */}

      <Divider />

      {/* Student Detail Accuracy Information */}
      <Typography {...StylesForm.text('p')}>
        Ensure your student details are accurate. Otherwise, use the student
        edit form above or contact an adminstrator to update your student
        information prior to project creation.
      </Typography>

      <Divider />

      {/* Accuracy Check Box  */}
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.checkBox}
          onChange={(e) => setForm({ ...form, checkBox: e.target.checked })}
        />

        <p>* The information is accurate</p>
      </Box>
    </>
  );
};

// Project Creation Submission
const ProjectNotice = ({ form }) => {
  const { createProjectError, createProject } = useAuth();
  const navigate = useNavigate();

  // Handles Creation of Project. If success: navigate to project dashboard of new project.
  const handleCreation = async () => {
    const u = await createProject(form);
    console.log(u);
    if (u) {
      if (u?.data?.success) {
        navigate(`/student/dashboard/project/${u.data.data._id}`);
        return;
      }
      return;
    }
  };

  // Utility function to toggle button
  const invalidInput = () => {
    const temp = { ...form };

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length > 10000
      )
    ) {
      return true;
    }

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length === 0
      )
    ) {
      return true;
    }

    if (!form.checkBox) {
      return true;
    }
  };

  return (
    <>
      {/* Title: Project Notice */}
      <Typography {...StylesForm.text('h6')}>Project Notice</Typography>

      <Divider sx={{ marginBottom: 1 }} />

      {/* Remember to invite team members notice */}
      <Alert severity="info">
        Remember to invite your team members to this project later in the
        invites page.
        <br />
        They will gain access upon accepting the invite from their dashboard.
      </Alert>

      <Divider sx={{ marginTop: 1 }} />

      {/* Create Project Button */}
      <Button
        variant="outlined"
        size="large"
        disabled={invalidInput()}
        onClick={handleCreation}
        sx={{
          p: 2,
          m: 1,
          height: '100%',
        }}
      >
        Create Project
      </Button>

      <Divider sx={{ marginTop: 1 }} />

      {/* Alert Status for Project Creatiion Request */}
      {createProjectError && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            p: 1,
            m: 1,
          }}
        >
          {createProjectError}
        </Alert>
      )}
    </>
  );
};

const EditUser = () => {
  const { notifySuccess, notifyError } = useFeedback();
  const { user } = useAuth();
  const [form, setForm] = useState({
    course: user.course,
    department: user.department,
    isTeamLeader: user.isTeamLeader,
  });
  const [isEditable, setIsEditable] = useState(false);
  const handleUpdate = async () => {
    try {
      const response = await updateMeStudent(form);
      if (response?.data?.success) {
        notifySuccess('Student details updated successfully');
        setIsEditable(false);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    } catch (error) {
      notifyError(
        'Error updating student details. Please file an IT support form for assistance.'
      );
      setIsEditable(false);
    }
  };

  if (!user) return <></>;

  const acceptedCourse = ['SD1', 'SD2'].includes(form.course);
  const acceptedDepartment = ['CIS', 'ECE', 'ME', 'IMSE'].includes(
    form.department
  );
  const acceptedIsTeamLeader = ['YES', 'NO'].includes(form.isTeamLeader);
  const notAllowed = form.department === 'ME' && form.course === 'SD1';
  const isValid =
    acceptedCourse && acceptedDepartment && acceptedIsTeamLeader && !notAllowed;

  return (
    <>
      <Box display="flex" justifyContent={'space-between'}>
        <Typography {...StylesForm.text('h6')} color="black">
          Update Student Details
        </Typography>
        {!isEditable && (
          <Button color="inherit" onClick={() => setIsEditable(!isEditable)}>
            Edit
          </Button>
        )}
        {isEditable && (
          <Button color="inherit" disabled={!isValid} onClick={handleUpdate}>
            Save
          </Button>
        )}
      </Box>

      <Divider sx={{ marginBottom: 1 }} />

      <Box display="flex" flexDirection={'column'} overflow={'auto'} px={1}>
        {
          <TextField
            {...StylesForm.input(
              'course',
              'COURSE ACCEPTABLE VALUES: SD1, SD2'
            )}
            color="info"
            value={form.course}
            onChange={(e) => setForm({ ...form, course: e.target.value })}
            disabled={!isEditable}
          />
        }
        <TextField
          {...StylesForm.input(
            'department',
            'DEPARTMENT ACCEPTABLE VALUES: CIS, ECE, ME, IMSE'
          )}
          color="info"
          value={form.department}
          onChange={(e) => setForm({ ...form, department: e.target.value })}
          disabled={!isEditable}
        />
        <TextField
          {...StylesForm.input(
            'isTeamLeader',
            'TEAM LEADER? ACCEPTABLE VALUES: YES, NO'
          )}
          value={form.isTeamLeader}
          onChange={(e) => setForm({ ...form, isTeamLeader: e.target.value })}
          color="info"
          disabled={!isEditable}
        />
        <Alert severity="info" sx={{ marginTop: 1 }} variant="outlined">
          <>
            Please ensure the accuracy of your student details. If any changes
            are warranted, please click on the edit button and update the
            information. There must be exactly one team leader for every
            project. If your require further assistance, you can fill out the{' '}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeHN8N-6AJ0xkW0x_48dNoBkMMLJXPzKGH4jkPgu3pw9kScgw/viewform?usp=header"
              target="_blank"
              rel="noreferrer"
            >
              IT Support Form.
            </a>
          </>
        </Alert>
      </Box>
    </>
  );
};

const DisclaimerInvites = () => {
  const [invites, setInvites] = useState([]);
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useFeedback();
  const { user } = useAuth();
  const onAccept = async (invite) => {
    try {
      const response = await acceptInvite(invite?._id);
      if (response?.data?.success) {
        notifySuccess('Invite accepted successfully');
        navigate(`/student/dashboard/project/${response?.data?.data?.id}`);
      }
    } catch (error) {
      notifyError('Error accepting invite');
      console.error('Error accepting invite:', error);
    }
  };

  const onReject = async (invite) => {
    try {
      const response = await declineInvite(invite?._id);
      if (response?.data?.success) {
        notifySuccess('Invite rejected successfully');
        const newInvites = invites.filter((i) => i?._id !== invite?._id);
        setInvites(newInvites);
      }
    } catch (error) {
      notifyError('Error rejecting invite');
      console.error('Error rejecting invite:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjectInvites();
        setInvites(response?.data?.data || []);
        if (response?.data?.data.length > 0) {
          notifySuccess('Recieved project invites. You can accept them here.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Typography {...StylesForm.text('h6')}>
        Project Disclaimer and Member Invites
      </Typography>

      <Divider sx={{ marginBottom: 1 }} />

      {user?.isTeamLeader === 'YES' ? (
        <>
          <Alert severity="info">
            You are a <strong>Team Leader</strong>. Create a new project here by
            filling out the form. Please make sure to include all the
            information. It will require you to fill out a IT support form to
            edit any of this information. Once completed, invite team members to
            join. They will gain access upon accepting the invite from their
            dashboard.
          </Alert>

          <Alert
            severity="info"
            sx={{
              marginTop: 2,
            }}
          >
            Make sure to scroll down and click the
            <strong> Create Project</strong> button once you have filled out the
            form.
          </Alert>
        </>
      ) : (
        <>
          <Alert severity="info">
            You are a <strong>Team Member</strong>. Accept project invites here
            and view the projects you're part of.
          </Alert>

          <Divider sx={{ marginTop: 1 }} />

          {!!invites && invites.length > 0 ? (
            <Box>
              <Typography {...StylesForm.text('p')}>
                <strong>Invites:</strong> {invites?.length} Result
                {invites?.length > 1 ? 's' : ''}
              </Typography>
              <ul>
                {invites.map((invite) => (
                  <li key={invite?._id} gap={1} display={'flex'}>
                    <Box marginBottom={1}>
                      <Typography {...StylesForm.text('p')}>
                        <strong> Title:</strong> {invite?.title}
                      </Typography>
                      <Box display="flex" gap={1} justifyContent={'start'}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => onAccept(invite)}
                        >
                          Accept Invite
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => onReject(invite)}
                        >
                          Reject Invite
                        </Button>
                      </Box>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          ) : (
            <Typography {...StylesForm.text('p')}>
              <strong>Invites:</strong> None Yet - Check back later
            </Typography>
          )}
        </>
      )}
    </>
  );
};

// Page that shows when a project is already created
const ExistingProjectDash = () => {
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Project Dashboard</Paper>
            </Grid>

            {/*Inner Grid Item 2: Inform Project Details */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectDetails project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: Project Members */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectMembers project={project} showLink={true} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3b: Timeline + Steps */}
            <Grid item lg={112} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformTimeline
                  project={project}
                  dueDates={config?.dueDates}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3c: Project Inbox */}

            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformInbox project={project?.data} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Project Abstract */}
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectAbstract
                  project={project}
                  dueDate={formatDate(config?.dueDates?.abstract)}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Link */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterLinkFinal)}
                  showLink={true}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Station */}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformStation project={project} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 5: Poster Image */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  dueDate={formatDate(config?.dueDates?.posterImageFinal)}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 6: Icon Resume*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformIconResume project={project} />
              </Paper>
            </Grid>

            {/*End of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when a new project is being created
const NewProjectDash = () => {
  const { user } = useAuth();
  const [form, setForm] = useState({
    title: '',
    description: '',
    clients: '',
    department: user.department,
    course: user.course,
    instructor: '',
    year: user.year,
    checkBox: false,
  });
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Creation Form
              </Paper>
            </Grid>

            {/*Inner Grid Item 1b: Discalimer and invites*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper
                {...Styles.dashboardContentWidget(
                  { lg: 340, md: 'auto' },
                  '#C1C1C1'
                )}
              >
                <EditUser />
              </Paper>
            </Grid>

            {/*Inner Grid Item 1b: Discalimer and invites*/}
            <Grid item lg={6} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <DisclaimerInvites />
              </Paper>
            </Grid>

            {user?.isTeamLeader === 'YES' && (
              <>
                {/*Inner Grid Item 2: Project Identity*/}
                <Grid item lg={6} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget()}>
                    <ProjectIdentity form={form} setForm={setForm} />
                  </Paper>
                </Grid>

                {/*Inner Grid Item 3: Project Details*/}
                <Grid item lg={6} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget()}>
                    <ProjectDetails form={form} setForm={setForm} />
                  </Paper>
                </Grid>

                {/*Inner Grid Item 4: Student Details*/}
                <Grid item lg={6} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget()}>
                    <StudentDetails form={form} setForm={setForm} />
                  </Paper>
                </Grid>

                {/*Inner Grid Item 5: Project Notice*/}
                <Grid item lg={6} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget()}>
                    <ProjectNotice form={form} setForm={setForm} />
                  </Paper>
                </Grid>
              </>
            )}

            {/*End Of Inner Grid*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectDashboard() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectDash} PageName="Student Dashboard" />
  ) : (
    <Dashboard
      Page={NewProjectDash}
      PageName="Student Dashboard - Create New Project"
    />
  );
}
