import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import LoadingPage from '../pages/Loading';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [createProjectError, setProjectError] = useState(null);

  const fetchUser = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_DOMAIN}api/v1/check-authentication`,
        {
          withCredentials: true,
        }
      );

      const response2 = await axios.get(
        `${process.env.REACT_APP_DOMAIN}api/v1/me`,
        {
          withCredentials: true,
        }
      );

      if (response2.data.success === true) {
        setUser(response2.data.data);
      }
      setLoading(false);
      return response2.data;
    } catch (error) {
      console.error('Error verifying token:', error);
      setLoading(false);
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const login = async (credentials) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}api/v1/login`,
        credentials,
        {
          withCredentials: true,
        }
      );

      const data = fetchUser();
      setLoginError(null);
      setRegisterError(null);
      return data;
    } catch (error) {
      console.error('Error logging in:', error);
      setLoading(false);

      if (error.response) {
        setLoginError(error.response.data.errMessage);
        return error.response.data;
      }
      setLoginError('Internal Server Error: Please Try Again Later!');
      return null;
    }
  };

  const registerStudent = async (credentials) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}api/v1/register/student`,
        credentials,
        {
          withCredentials: true,
        }
      );

      const data = fetchUser();
      setLoginError(null);
      setRegisterError(null);
      return data;
    } catch (error) {
      console.error('Error Signing Up:', error);
      setLoading(false);
      if (error.response) {
        setRegisterError(error.response.data.errMessage);
        return error.response.data;
      }
      setRegisterError('Internal Server Error: Please Try Again Later!');
      return null;
    }
  };

  const registerFaculty = async (credentials) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}api/v1/register/faculty`,
        credentials,
        {
          withCredentials: true,
        }
      );

      const data = fetchUser();
      setLoginError(null);
      setRegisterError(null);
      return data;
    } catch (error) {
      console.error('Error Signing Up:', error);
      setLoading(false);
      if (error.response) {
        setRegisterError(error.response.data.errMessage);
        return error.response.data;
      }
      setRegisterError('Internal Server Error: Please Try Again Later!');
      return null;
    }
  };

  const registerJudge = async (credentials) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}api/v1/register/judge/${credentials.token}`,
        credentials,
        {
          withCredentials: true,
        }
      );

      const data = fetchUser();
      setLoginError(null);
      setRegisterError(null);
      return data;
    } catch (error) {
      console.error('Error Signing Up:', error);
      setLoading(false);
      if (error.response) {
        setRegisterError(error.response.data.errMessage);
        return error.response.data;
      }
      setRegisterError('Internal Server Error: Please Try Again Later!');
      return null;
    }
  };

  const createProject = async (details) => {
    setLoading(true);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_DOMAIN}api/v1/project`,
        details,
        {
          withCredentials: true,
        }
      );

      await fetchUser();

      setProjectError(false);

      return data;
    } catch (error) {
      console.error('Error creating project:', error);
      setLoading(false);
      if (error.response) {
        setProjectError(error.response.data.errMessage);
        return error.response.data;
      }
      setProjectError('Internal Server Error: Please Try Again Later!');
      return null;
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}api/v1/logout`,
        {
          withCredentials: true,
        }
      );

      if (response.data.success === true) {
        setUser(null);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loginError,
        login,
        logout,
        registerStudent,
        registerFaculty,
        registerError,
        createProject,
        createProjectError,
        registerJudge,
        isLoggedIn: !!user,
      }}
    >
      {loading ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
