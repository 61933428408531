import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import EditableRubric from './EditableRubric';
import { ExpandMore } from '@mui/icons-material';

function RubricHeader({ rubric, setRubric, handleShapeChange }) {
  const handleRubricTypeChange = (e) => {
    if (e.target.value === 'BestInCollege') {
      setRubric({
        ...rubric,
        type: e.target.value,
        department: 'N/A',
        course: 'SD2',
      });
    } else if (e.target.value === 'Innovation') {
      setRubric({
        ...rubric,
        type: e.target.value,
        department: 'N/A',
        course: 'SD2',
      });
    } else {
      setRubric({ ...rubric, type: e.target.value });
    }
  };

  const isDepartment = rubric?.type === 'Department';
  const isBestInCollege = rubric?.type === 'BestInCollege';
  const isInnovation = rubric?.type === 'Innovation';
  const isSponsor = rubric?.type === 'Sponsor';
  const isOther = rubric?.type === 'Other';

  return (
    rubric && (
      <Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {/* Title and Description and published*/}
          <Box display={'flex'} sx={{ alignItems: 'center', gap: 3 }}>
            <Typography variant="subtitle"> Title </Typography>
            <TextField
              variant="standard"
              value={rubric?.title || ''}
              onChange={(e) =>
                setRubric({
                  ...rubric,
                  title: e.target.value,
                })
              }
              fullWidth
            />
          </Box>
          <Box display={'flex'} sx={{ alignItems: 'center', gap: 2 }}>
            <Typography variant="subtitle"> Description </Typography>
            <Input
              value={rubric?.description || ''}
              onChange={(e) =>
                setRubric({
                  ...rubric,
                  description: e.target.value,
                })
              }
              fullWidth
              multiline={true}
            />
          </Box>

          <Box display={'flex'} sx={{ alignItems: 'center', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Competition</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rubric?.type || ''}
                label="Type"
                onChange={handleRubricTypeChange}
              >
                <MenuItem value={'Department'}>Best in Department</MenuItem>
                <MenuItem value={'BestInCollege'}>Best in College</MenuItem>
                <MenuItem value={'Innovation'}>Innovation</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={isBestInCollege || isInnovation}>
              <InputLabel id="demo-simple-select-label">Course</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rubric?.course || ''}
                label="Course"
                onChange={(e) =>
                  setRubric({ ...rubric, course: e.target.value })
                }
              >
                <MenuItem value={'SD1'}>Senior Design 1</MenuItem>
                <MenuItem value={'SD2'}>Senior Design 2</MenuItem>

                <MenuItem value={'N/A'}>ALL</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={isBestInCollege}>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rubric?.department || ''}
                label="Course"
                onChange={(e) =>
                  setRubric({ ...rubric, department: e.target.value })
                }
              >
                <MenuItem value={'CIS'}>CIS</MenuItem>
                <MenuItem value={'ECE'}>ECE</MenuItem>
                <MenuItem value={'ME'}>ME</MenuItem>
                <MenuItem value={'IMSE'}>IMSE</MenuItem>

                <MenuItem value={'N/A'}>ALL</MenuItem>
              </Select>
            </FormControl>
            <Divider />
          </Box>
          <Box
            display={'flex'}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <TextField
              id="standard-number"
              label="Number of Rubric Cateogries"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={rubric?.numOfRubricCategories}
              onChange={(e) => {
                handleShapeChange(e.target.value, rubric?.numOfScoreCategories);
              }}
            />

            <TextField
              id="standard-number2"
              label="Number of Score Cateogries"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={rubric?.numOfScoreCategories}
              onChange={(e) => {
                handleShapeChange(
                  rubric?.numOfRubricCategories,
                  e.target.value
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    )
  );
}

export default function RubricForm({ rubric, setRubric }) {
  function handleChange(array, index, change) {
    if (array === 'scoreCategories') {
      let temp = rubric.scoreCategories;
      temp[index] = change;
      setRubric({
        ...rubric,
        scoreCategories: temp,
      });
    }
    if (array === 'rubricCategories') {
      let temp = rubric.rubricCategories;
      temp[index] = change;
      setRubric({
        ...rubric,
        rubricCategories: temp,
      });
    }
    if (array === 'weights') {
      let temp = rubric.weights;
      temp[index] = change;
      setRubric({
        ...rubric,
        weights: temp,
      });
    }
    if (array === 'scoreCategoriesDescription') {
      let temp = rubric.scoreCategoriesDescriptions;
      temp[index.rubricCategory][index.scoreCategory] = change;
      setRubric({
        ...rubric,
        scoreCategoriesDescriptions: temp,
      });
    }
  }

  function handleShapeChange(numRubricCat, numScoreCat) {
    numRubricCat = +numRubricCat;
    numScoreCat = +numScoreCat;
    if (numRubricCat < 0 || numScoreCat < 0) {
      return;
    }

    if (numRubricCat > 10 || numScoreCat > 10) {
      return;
    }

    if (isNaN(numRubricCat) || isNaN(numScoreCat)) {
      return;
    }

    let rubricCategories = [];
    let scoreCategories = [];
    let scoreCategoriesDescriptions = [];
    let weights = [];
    for (let i = 0; i < numRubricCat; i++) {
      rubricCategories.push('');
      weights.push(1);
      scoreCategoriesDescriptions.push([]);
      for (let j = 0; j < numScoreCat; j++) {
        scoreCategoriesDescriptions[i].push('');
      }
    }
    for (let i = 0; i < numScoreCat; i++) {
      scoreCategories.push('');
    }
    setRubric({
      ...rubric,
      rubricCategories,
      scoreCategories,
      scoreCategoriesDescriptions,
      weights,
      numOfRubricCategories: numRubricCat,
      numOfScoreCategories: numScoreCat,
    });
  }

  return (
    rubric && (
      <>
        <RubricHeader
          rubric={rubric}
          setRubric={setRubric}
          handleShapeChange={handleShapeChange}
        />
        <br />
        <Accordion sx={{ width: '99%' }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Rubric Example
          </AccordionSummary>
          <AccordionDetails>
            <img
              src="https://senior-design-competion-production.nyc3.cdn.digitaloceanspaces.com/MISC/Screenshot%202025-02-14%20at%2011.35.22%E2%80%AFAM.png"
              alt="rubric example"
              style={{ width: '100%' }}
            />
          </AccordionDetails>
        </Accordion>

        <br />
        <EditableRubric rubric={rubric} handleChange={handleChange} />
      </>
    )
  );
}
