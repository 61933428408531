import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Chip } from '@mui/material';
import UserModal from './UserModal';
import { formatID, formatName } from '../utils/formatAttributes';
import { useAuth } from '../utils/AuthContext';

const roleColors = (role) => {
  switch (role) {
    case 'STUDENT':
      return 'primary';
    case 'ADMIN':
      return 'secondary';
    case 'JUDGE':
      return 'warning';
    case 'FACULTY':
      return 'success';
    default:
      return 'default';
  }
};

export default function UsersTable({ rows, flag, setFlag, adminMode = false }) {
  const { user } = useAuth();
  const Icon = (row) =>
    row?.icon ? (
      <Avatar
        alt={row.fullName}
        src={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_ICONS}${row?.icon}`}
        sx={{ width: 50, height: 50 }}
      />
    ) : (
      <Avatar
        alt={row?.fullName}
        src={row?.avatar}
        sx={{ width: 50, height: 50 }}
      />
    );
  return (
    <TableContainer component={Paper} sx={{ p: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Users</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Full Name
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Email
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Department
            </TableCell>
            {user?.department !== 'ME' && (
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Course
              </TableCell>
            )}
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Role
            </TableCell>
            {adminMode && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.fullName}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {Icon(row)}
              </TableCell>
              <TableCell align="right">
                {formatName(row?.prefix, row?.fullName)}
              </TableCell>
              <TableCell align="right">{row?.email}</TableCell>
              <TableCell align="right">{row?.department}</TableCell>
              {adminMode &&
                (row?.department !== 'ME' ? (
                  <TableCell align="right">{row?.course}</TableCell>
                ) : (
                  <TableCell align="right">N/A</TableCell>
                ))}
              {!adminMode && user?.department !== 'ME' && (
                <TableCell align="right">{row?.course}</TableCell>
              )}
              <TableCell align="right">
                <Chip
                  label={row?.role}
                  color={roleColors(row?.role)}
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                />
              </TableCell>
              {adminMode && (
                <TableCell align="right">
                  <UserModal user={row} flag={flag} setFlag={setFlag} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
